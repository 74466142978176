
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Entitytable from "@/components/EntityTable.vue";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import RequestPayoutModal from "@/components/RequestPayoutModal.vue";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "payouts-details",
  components: {
    Entitytable,
    RequestPayoutModal,
  },
  computed: {
    sliderValue(element) {
      return element.status == "Approved";
    },
  },
  methods: {
    handleChangeApprove(request_id, extra) {
      alert(request_id);
      alert(extra);
    },
    catchErrors(error) {
      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    updateRequest(request_id, approve) {
      return ApiService.update("requests", request_id, {
        confirmed: approve,
      });
    },

    refreshModal() {
      this.modal.show();
    },
    refreshData() {
      (this.$refs.txEntities as any).retrieveData();
    },
    resetData() {
      this.modalKey = Math.random().toString();
      alert(this.modalKey);
    },
    getDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getTime(date) {
      return moment(date).format("hh:mm:ss");
    },
  },
  data() {
    return {
      invoice: "",
      modalKey: "",
      columnData: [
        {
          label: "ID",
          name: "id",
        },
        {
          label: "Date",
          name: "date",
        },
        {
          label: "Affiliate",
          name: "affiliate",
        },
        {
          label: "Status",
          name: "status_string",
        },
      ],
    };
  },
  mounted() {
    const element = document.getElementById(
      "request-payout-modal"
    ) as HTMLElement;
    this.modal = new Modal(element, {});
  },
  setup() {
    let modal = ref<Modal>();

    onMounted(() => {
      setCurrentPageTitle("Requests");
    });

    return { modal };
  },
});
